import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, Injector } from '@angular/core';
import { SeoAwareBaseComponent } from '../common-theme/seo/seo-aware-base.component';
import { Subscription } from 'rxjs';
import { BlockHelperService } from '../common-theme/index';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { OurStoryDialogComponent } from './our-story-dialog/our-story-dialog.component';
var ConceptComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ConceptComponent, _super);
    function ConceptComponent(blockHelper, route, dialog, injector) {
        var _this = _super.call(this, injector) || this;
        _this.blockHelper = blockHelper;
        _this.route = route;
        _this.dialog = dialog;
        _this.injector = injector;
        _this.subscription = new Subscription();
        return _this;
    }
    ConceptComponent.prototype.openDialog = function (index) {
        this.dialog.open(OurStoryDialogComponent, {
            data: {
                block: this.blockHelper.filterBlocksByKey(this.blocks, 'why_us_read_more' + index)
            },
            width: '500px'
        });
    };
    ConceptComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription.add(this.route.data.subscribe(function (data) {
            _this.blocks = data.blocks;
            _this.setTitle(_this.blockHelper.filterBlocksByKey(_this.blocks, 'seo').properties.title);
            _this.setMetaDescription(_this.blockHelper.filterBlocksByKey(_this.blocks, 'seo').properties.meta);
            _this.sliders = _this.blocks.filter(function (obj) {
                return obj.key.startsWith('slider');
            });
        }));
    };
    ConceptComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return ConceptComponent;
}(SeoAwareBaseComponent));
export { ConceptComponent };
