import * as tslib_1 from "tslib";
import { LocaleAwareBaseComponent } from '../../common-theme/index';
import { Block } from '../../common-theme/models/index';
var NewsBoxComponent = /** @class */ (function (_super) {
    tslib_1.__extends(NewsBoxComponent, _super);
    function NewsBoxComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return NewsBoxComponent;
}(LocaleAwareBaseComponent));
export { NewsBoxComponent };
