import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, Injector } from '@angular/core';
import { SeoAwareBaseComponent } from '../common-theme/seo/seo-aware-base.component';
import { Subscription } from 'rxjs';
import { BlockHelperService } from './../common-theme/index';
import { ActivatedRoute } from '@angular/router';
var NewsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(NewsComponent, _super);
    function NewsComponent(blockHelper, route, injector) {
        var _this = _super.call(this, injector) || this;
        _this.blockHelper = blockHelper;
        _this.route = route;
        _this.injector = injector;
        _this.subscription = new Subscription();
        _this.news = [
            'portofoliul-local-al-hagag-development-europe-depaseste-200-milioane-euro-',
            'palatul-stirbey-va-fi-restaurat-de-hagag-development-europe',
            'hagag-development-europe-finalizeaza-structura-primelor-trei-imobile-din-proiectul-h-pipera-lake',
            'hagag-development-europe-investeste-14-mil-euro-intr-un-nou-proiect-de-spatii-de-birouri-din-bucuresti',
            'israelienii-de-la-hagag-au-inceput-constructia-proiectului-rezidential-h-pipera-lake-o-investitie-de-90-milioane-de-euro',
            'hagag-development-europe-a-obtinut-autorizatia-de-constructie-pentru-h-victoriei-139',
            'global-vision-asigura-serivciile-property-management-pentru-birourile-hagag',
            'dezvoltatorul-imobiliar-hagag-development-europe-finalizeaza-structura-primelor'
        ];
        return _this;
    }
    NewsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription.add(this.route.data.subscribe(function (data) {
            _this.blocks = data.blocks;
            _this.setTitle(_this.blockHelper.filterBlocksByKey(_this.blocks, 'seo').properties.title);
            _this.setMetaDescription(_this.blockHelper.filterBlocksByKey(_this.blocks, 'seo').properties.meta);
        }));
    };
    NewsComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return NewsComponent;
}(SeoAwareBaseComponent));
export { NewsComponent };
