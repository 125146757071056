import { LocaleService } from './../seo/locale.service';
import { DomSanitizer } from '@angular/platform-browser';
// http://www.whirp.org/socialmedia/google/querystrings.htm
var GoogleMapIframeComponent = /** @class */ (function () {
    function GoogleMapIframeComponent(sanitizer, localeService) {
        this.sanitizer = sanitizer;
        this.localeService = localeService;
        this.zoom = 14;
    }
    Object.defineProperty(GoogleMapIframeComponent.prototype, "iframeSrc", {
        get: function () {
            if (this._iframeSrc)
                return this._iframeSrc;
            if (!this.latitude && !this.longitude) {
                this._iframeSrc = null;
                return null;
            }
            this._iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl('https://maps.google.com/maps?width=700&height=440&hl=' + this.localeService.getCurrentLocale() +
                '&q=' + this.latitude + '%2C' + this.longitude +
                '&ie=UTF8&t=&z=' + this.zoom + '&output=embed');
            return this._iframeSrc;
        },
        enumerable: true,
        configurable: true
    });
    return GoogleMapIframeComponent;
}());
export { GoogleMapIframeComponent };
