import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, Injector } from '@angular/core';
import { SeoAwareBaseComponent } from '../../common-theme/seo/seo-aware-base.component';
import { Subscription } from 'rxjs';
import { BlockHelperService } from '../../common-theme/index';
import { BlocksService } from '../../common-theme/api/index';
import { ActivatedRoute } from '@angular/router';
var ArticleComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ArticleComponent, _super);
    function ArticleComponent(blockHelper, blocksService, route, injector) {
        var _this = _super.call(this, injector) || this;
        _this.blockHelper = blockHelper;
        _this.blocksService = blocksService;
        _this.route = route;
        _this.injector = injector;
        _this.subscription = new Subscription();
        return _this;
    }
    ArticleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription.add(this.route.parent.data.subscribe(function (data) {
            _this.blocks = data.blocks;
            _this.subscription.add(_this.route.params.subscribe(function (params) {
                _this.projectKey = params['project_key'];
                _this.block = _this.blockHelper.filterBlocksByKey(_this.blocks, _this.projectKey);
                _this.setTitle(_this.block.properties.title);
                _this.blocksService.getBlocks(_this.projectKey).then(function (blocks) {
                    _this.sliders = blocks.filter(function (obj) {
                        return obj.key.startsWith('slider');
                    });
                });
            }));
        }));
    };
    ArticleComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return ArticleComponent;
}(SeoAwareBaseComponent));
export { ArticleComponent };
