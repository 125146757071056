import * as tslib_1 from "tslib";
import { LocaleAwareBaseComponent } from '../../common-theme/index';
import { Block } from '../../common-theme/models/index';
var CommercialBoxComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CommercialBoxComponent, _super);
    function CommercialBoxComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return CommercialBoxComponent;
}(LocaleAwareBaseComponent));
export { CommercialBoxComponent };
