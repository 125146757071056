import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, Injector } from '@angular/core';
import { SeoAwareBaseComponent } from '../common-theme/seo/seo-aware-base.component';
import { Subscription } from 'rxjs';
import { BlockHelperService } from '../common-theme/index';
import { ActivatedRoute } from '@angular/router';
var CommercialComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CommercialComponent, _super);
    function CommercialComponent(blockHelper, route, window, injector) {
        var _this = _super.call(this, injector) || this;
        _this.blockHelper = blockHelper;
        _this.route = route;
        _this.window = window;
        _this.injector = injector;
        _this.subscription = new Subscription();
        _this.activeImg = 1;
        _this.projects = [
            'h-victoriei-109',
            'h-tudor-arghezi-21',
            'h-stirbey-gallery'
        ];
        return _this;
    }
    CommercialComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription.add(this.route.data.subscribe(function (data) {
            _this.blocks = data.blocks;
            _this.setTitle(_this.blockHelper.filterBlocksByKey(_this.blocks, 'seo').properties.title);
            _this.setMetaDescription(_this.blockHelper.filterBlocksByKey(_this.blocks, 'seo').properties.meta);
            _this.sliders = _this.blocks.filter(function (obj) {
                return obj.key.startsWith('slider');
            });
        }));
    };
    CommercialComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    CommercialComponent.prototype.scroll = function () {
        var _this = this;
        this.window.setTimeout(function () {
            _this.window.scrollTo(0, 400);
        }, 100);
    };
    return CommercialComponent;
}(SeoAwareBaseComponent));
export { CommercialComponent };
