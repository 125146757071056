import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, Injector } from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { SeoAwareBaseComponent } from '../common-theme/seo/seo-aware-base.component';
import { BlockHelperService } from '../common-theme/index';
import { ActivatedRoute } from '@angular/router';
import { GalleryImagesService } from './gallery-images.service';
import { ListTrackingService } from '../common-theme/utils/list-tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
var GalleryComponent = /** @class */ (function (_super) {
    tslib_1.__extends(GalleryComponent, _super);
    function GalleryComponent(blockHelper, route, imagesService, listTrackingService, _lightbox, _lighboxConfig, translate, injector, window) {
        var _this = _super.call(this, injector) || this;
        _this.blockHelper = blockHelper;
        _this.route = route;
        _this.imagesService = imagesService;
        _this.listTrackingService = listTrackingService;
        _this._lightbox = _lightbox;
        _this._lighboxConfig = _lighboxConfig;
        _this.translate = translate;
        _this.injector = injector;
        _this.window = window;
        _this.realImages = [
            { url: '/assets/gallery/real/1.jpg' },
            { url: '/assets/gallery/real/2.jpg' },
            { url: '/assets/gallery/real/3.jpg' },
            { url: '/assets/gallery/real/4.jpg' },
            { url: '/assets/gallery/real/5.jpg' },
            { url: '/assets/gallery/real/6.jpg' },
            { url: '/assets/gallery/real/7.jpg' },
            { url: '/assets/gallery/real/8.jpg' },
            { url: '/assets/gallery/real/9.jpg' },
            { url: '/assets/gallery/real/10.jpg' },
            { url: '/assets/gallery/real/11.jpg' },
            { url: '/assets/gallery/real/12.jpg' },
            { url: '/assets/gallery/real/13.jpg' },
            { url: '/assets/gallery/real/14.jpg' },
            { url: '/assets/gallery/real/15.jpg' },
            { url: '/assets/gallery/real/16.jpg' },
            { url: '/assets/gallery/real/17.jpg' },
            { url: '/assets/gallery/real/18.jpg' },
            { url: '/assets/gallery/real/19.jpg' },
            { url: '/assets/gallery/real/20.jpg' },
            { url: '/assets/gallery/real/21.jpg' },
            { url: '/assets/gallery/real/22.jpg' },
            { url: '/assets/gallery/real/23.jpg' },
            { url: '/assets/gallery/real/24.jpg' },
            { url: '/assets/gallery/real/25.jpg' },
            { url: '/assets/gallery/real/26.jpg' }
        ];
        _this.interiorImages = [
            { url: '/assets/gallery/interior/1.jpg' },
            { url: '/assets/gallery/interior/2.jpg' },
            { url: '/assets/gallery/interior/3.jpg' },
            { url: '/assets/gallery/interior/4.jpg' },
            { url: '/assets/gallery/interior/5.jpg' },
            { url: '/assets/gallery/interior/6.jpg' },
            { url: '/assets/gallery/interior/7.jpg' },
            { url: '/assets/gallery/interior/8.jpg' },
            { url: '/assets/gallery/interior/9.jpg' },
            { url: '/assets/gallery/interior/10.jpg' },
            { url: '/assets/gallery/interior/11.jpg' },
            { url: '/assets/gallery/interior/12.jpg' },
            { url: '/assets/gallery/interior/13.jpg' },
            { url: '/assets/gallery/interior/14.jpg' },
            { url: '/assets/gallery/interior/15.jpg' },
            { url: '/assets/gallery/interior/16.jpg' },
            { url: '/assets/gallery/interior/17.jpg' },
            { url: '/assets/gallery/interior/18.jpg' },
            { url: '/assets/gallery/interior/19.jpg' },
            { url: '/assets/gallery/interior/20.jpg' },
            { url: '/assets/gallery/interior/21.jpg' },
            { url: '/assets/gallery/interior/22.jpg' },
            { url: '/assets/gallery/interior/23.jpg' },
            { url: '/assets/gallery/interior/24.jpg' },
            { url: '/assets/gallery/interior/25.jpg' },
            { url: '/assets/gallery/interior/26.jpg' },
            { url: '/assets/gallery/interior/27.jpg' },
            { url: '/assets/gallery/interior/28.jpg' },
            { url: '/assets/gallery/interior/29.jpg' },
            { url: '/assets/gallery/interior/30.jpg' },
            { url: '/assets/gallery/interior/31.jpg' },
            { url: '/assets/gallery/interior/32.jpg' },
            { url: '/assets/gallery/interior/33.jpg' }
        ];
        _this.exteriorImages = [
            { url: '/assets/gallery/exterior/1.JPG' },
            { url: '/assets/gallery/exterior/2.JPG' },
            { url: '/assets/gallery/exterior/3.JPG' },
            { url: '/assets/gallery/exterior/4.JPG' },
            { url: '/assets/gallery/exterior/5.JPG' }
        ];
        _this.interiorRow1 = [
            { url: '/assets/gallery/interior/1.jpg' },
            { url: '/assets/gallery/interior/2.jpg' },
            { url: '/assets/gallery/interior/3.jpg' },
            { url: '/assets/gallery/interior/4.jpg' },
            { url: '/assets/gallery/interior/5.jpg' }
        ];
        _this.months = [];
        _this.translateSubscription = new Subscription();
        return _this;
    }
    Object.defineProperty(GalleryComponent.prototype, "currentSeason", {
        get: function () {
            return this._currentSeason;
        },
        set: function (currentSeason) {
            this._currentSeason = currentSeason;
            this.months = this.imagesService.getBySeason(this.currentYear, currentSeason);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GalleryComponent.prototype, "currentYear", {
        get: function () {
            return this._currentYear;
        },
        set: function (currentYear) {
            this._currentYear = currentYear;
            this.currentSeason = 0;
        },
        enumerable: true,
        configurable: true
    });
    GalleryComponent.prototype.ngOnInit = function () {
        var _this = this;
        // this.setTitle('Actualizări de pe șantierul Central');
        // const today = new Date();
        this.currentYear = 2020;
        // this.currentSeason = Math.floor(today.getMonth() / 4);
        this.currentSeason = 0;
        this.subscription.add(this.route.data.subscribe(function (data) {
            _this.blocks = data.blocks;
            _this.setTitle(_this.blockHelper.filterBlocksByKey(_this.blocks, 'seo').properties.title);
            _this.setMetaDescription(_this.blockHelper.filterBlocksByKey(_this.blocks, 'seo').properties.meta);
            _this.sliders = _this.blocks.filter(function (obj) {
                return obj.key.startsWith('slider');
            });
        }));
    };
    GalleryComponent.prototype.ngOnDestroy = function () {
        this.translateSubscription.unsubscribe();
    };
    GalleryComponent.prototype.open = function (images, index, event, caption) {
        var e_1, _a;
        if (caption === void 0) { caption = ''; }
        if (event)
            event.stopPropagation();
        if (this.window)
            this._lighboxConfig.positionFromTop = 20;
        var albums = [];
        try {
            for (var images_1 = tslib_1.__values(images), images_1_1 = images_1.next(); !images_1_1.done; images_1_1 = images_1.next()) {
                var image = images_1_1.value;
                var album = {
                    src: image.url,
                    caption: caption,
                    thumb: image.url
                };
                albums.push(album);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (images_1_1 && !images_1_1.done && (_a = images_1.return)) _a.call(images_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this._lightbox.open(albums, index, { wrapAround: true, showImageNumberLabel: true });
    };
    return GalleryComponent;
}(SeoAwareBaseComponent));
export { GalleryComponent };
